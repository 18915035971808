import React, { useRef } from 'react'

import DoneIcon from '../../assets/svg/done.inline.svg'
import * as styles from './form.module.scss'

const EmailForm = ({
    formId,
    loading,
    message,
    formName,
    email,
    handleSubmit,
    submitted,
    isSubmitSuccess,
    setBot,
    setEmail,
}) => {
    const inputEl = useRef(null)

    return (
        <form id={formId ? formId : 'contact'} onSubmit={e => handleSubmit(e, 'email')}>
            <input type='hidden' name='bot' onChange={e => setBot(e.target.value)} />
            <input type='hidden' name='form-name' value={formName ? formName : 'contact'} />
            <div className={styles.formRowFullWidth}>
                {isSubmitSuccess ? (
                    <div className={styles.success}>
                        <DoneIcon />
                        <h5>Thank you!</h5>
                        <p className={styles.formMessage} dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                ) : (
                    <>
                        <div className={styles.formItem}>
                            <label>
                                <input
                                    ref={inputEl}
                                    // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/"
                                    type='email'
                                    name='email'
                                    id={formName + 'email'}
                                    className={styles.formField}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    disabled={loading}
                                    placeholder='Email'
                                    required
                                />
                            </label>
                        </div>
                        <button type='submit' className={styles.formButton} disabled={loading}>
                            <span style={{ display: 'inline-block', marginTop: 2 + 'px' }}>Subscribe</span>
                            {loading && <span className={styles.formLoader} />}
                        </button>
                        {submitted && (
                            <p className={styles.formMessageAbsolute} dangerouslySetInnerHTML={{ __html: message }} />
                        )}
                    </>
                )}
            </div>
        </form>
    )
}

export default EmailForm
