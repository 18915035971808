import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button, Drawer, Grid, Affix, Dropdown, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import loadable from '@loadable/component'

import Logo from '../Logo'
import GlobalMenu from '../Menu'
import CommonContacts from '../CommonContacts'
import SocialList from '../SocialList'

import SearchIcon from '../../assets/svg/search.inline.svg'
import CloseIcon from '../../assets/svg/close.inline.svg'
import CaretDownIcon from '../../assets/svg/caretDown.inline.svg'
import GlobeIcon from '../../assets/svg/globe.inline.svg'
import * as styles from './header.module.scss'
import * as menuStyles from '../Menu/menu.module.scss'

const Search = loadable(() => import('../Search'))

const Header = props => {
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [searchVisible, setSearchVisible] = useState(false)
    const { SubMenu, Item } = Menu
    const { useBreakpoint } = Grid
    const { xl } = useBreakpoint()

    const desktopLangMenu = (
        <Menu className={styles.langDropDownSubmenu}>
            <Item key='0'>
                <Link to={process.env.GATSBY_RU_SITE_URL + (props.pageUri ? props.pageUri.replace('/en', '') : '')}>
                    Русский
                </Link>
            </Item>
        </Menu>
    )

    const handleDrawerShow = () => {
        setDrawerVisible(true)
    }

    const handleDrawerClose = () => {
        setDrawerVisible(false)
    }

    const handleSearchButtonClick = () => {
        if (!searchVisible) {
            setSearchVisible(true)
        } else {
            setSearchVisible(false)
            handleDrawerClose()
        }
    }

    return (
        <header className={styles.wrapper}>
            {xl && (
                <div className={styles.row}>
                    <div className='global-container'>
                        <CommonContacts />
                        <Dropdown overlay={desktopLangMenu} placement='bottomRight' trigger={['click']}>
                            <a className={styles.langToggleButton} onClick={e => e.preventDefault()}>
                                <GlobeIcon />
                                <span>English</span>
                                <CaretDownIcon />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            )}
            <Affix>
                <div className={styles.row}>
                    <div className='global-container'>
                        <>
                            <Logo isLink logoId='header' />
                            {/* Mobile */}
                            <Search setSearchVisible={setSearchVisible} visible={searchVisible} />
                            <Button
                                size='large'
                                icon={searchVisible ? <CloseIcon /> : <SearchIcon />}
                                aria-label='Search trigger'
                                className={styles.menuMobileSearchButton}
                                onClick={() => handleSearchButtonClick()}
                            />
                            <Button
                                size='large'
                                icon={<MenuOutlined />}
                                aria-label='Mobile menu trigger'
                                className={styles.menuMobileTrigger}
                                onClick={() => handleDrawerShow()}
                            />
                            <Drawer
                                placement='right'
                                className={styles.menuMobileDrawer}
                                onClose={handleDrawerClose}
                                closeIcon={<CloseIcon />}
                                destroyOnClose={true}
                                mask={false}
                                visible={drawerVisible}
                            >
                                <GlobalMenu mode='inline' />
                                <CommonContacts withIcon />
                                <Menu
                                    className={menuStyles.menuWrapper + ' ' + styles.langMobileToggleButton}
                                    mode='inline'
                                >
                                    <SubMenu
                                        popupOffset={[0, 0]}
                                        title={
                                            <a onClick={e => e.preventDefault()}>
                                                <GlobeIcon />
                                                <span>English</span>
                                                <CaretDownIcon />
                                            </a>
                                        }
                                    >
                                        <Item key='0'>
                                            <Link
                                                to={
                                                    process.env.GATSBY_RU_SITE_URL +
                                                    (props.pageUri ? props.pageUri.replace('/en', '') : '')
                                                }
                                            >
                                                Русский
                                            </Link>
                                        </Item>
                                    </SubMenu>
                                </Menu>
                                <SocialList theme='black' />
                            </Drawer>
                            {/*  Desktop  */}
                            <GlobalMenu />
                            {xl && (
                                <Button
                                    size='large'
                                    icon={searchVisible ? <CloseIcon /> : <SearchIcon />}
                                    aria-label='Search trigger'
                                    className={styles.menuSearchButton}
                                    onClick={() => handleSearchButtonClick()}
                                />
                            )}
                            <SocialList theme='black' />
                        </>
                    </div>
                </div>
            </Affix>
        </header>
    )
}

export default Header
